import { memoizedCall } from '@zupr/hooks/request-urql'

const requestStore = (state = {}, action) => {
    switch (action.type) {
        case 'REQUEST_STORE':
            return { ...state, [`${action.method}:${action.url}`]: action.data }
        case 'INVALIDATE_ALL':
            memoizedCall?.cache?.clear?.()
            return {}
        default:
            return state
    }
}

export default requestStore
