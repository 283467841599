export default function item(state = {}, action) {
    switch (action.type) {
        case 'ITEM_URL_STORE':
            return { ...state, [action.url]: action.item }

        case 'LIST_STORE':
        case 'ITEMS_STORE':
            return { ...state, ...action.items }

        case 'INVALIDATE_ALL':
            return {}

        default:
            return state
    }
}
