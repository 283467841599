import { store } from '@zupr/redux'
import { SiteProps } from '@zupr/types/next'
import { imageProviderHashToUrl } from '@zupr/utils/image'
import '@zupr/web/src/scss/shared.scss'
import Error from 'next/error'
import Head from 'next/head'
import { Provider as ReduxProvider } from 'react-redux'

interface Props {
    Component: any
    pageProps: SiteProps
}

const ZuprApp = ({ Component, pageProps }: Props) => {
    if (pageProps.flags?.includes('maintenance')) {
        return <Error statusCode={503} title="Maintenance" />
    }

    const { title, description, image, canonical, noindex } =
        pageProps.meta || {
            title: 'Zupr',
            canonical: '',
        }

    const {
        name,
        brand,
        openGraphTitle,
        openGraphSitename,
        openGraphDescription,
        openGraphImage,
        data,
        domains,
        preferences,
        location,
    } = pageProps.domainConfig || {}

    const config = pageProps.domain?.config

    const canonicalBase = domains?.[0] || pageProps.host || 'www.zupr.nl'
    const siteName = brand || name || ''
    const siteDescription = description || openGraphDescription
    const siteImage = image || openGraphImage
    const siteCanonical = `https://${canonicalBase}${canonical}`

    let siteTitle = openGraphTitle || siteName
    if (title) {
        siteTitle = !!siteName ? `${title} | ${siteName}` : title
    }

    return (
        <div id="app">
            <Head>
                <title>{siteTitle}</title>
                <meta
                    key="description"
                    name="description"
                    content={siteDescription}
                />

                <link rel="canonical" href={siteCanonical} />

                <meta property="og:title" content={siteTitle} />
                <meta property="og:url" content={siteCanonical} />
                <meta
                    property="og:site_name"
                    content={openGraphSitename || siteName || location?.title}
                />
                <meta property="og:image" content={siteImage} />
                <meta property="og:description" content={siteDescription} />

                {noindex && <meta name="robots" content="noindex" />}

                {config && 'sitemapUrl' in config && <link rel="sitemap" href={config.sitemapUrl}></link>}

                {!(config && 'logoImageProviderHash' in config && config.logoImageProviderHash) && ( 
                    <>
                        <link rel="icon" href="/favicon.ico" sizes="any" />
                        <link rel="icon" href="/icon.svg" type="image/svg+xml" />                
                    </>
                )} 
                {config && 'logoImageProviderHash' in config && config.logoImageProviderHash && (
                    <link rel="shortcut icon" type="image/png" href={imageProviderHashToUrl({ id: config.logoImageProviderHash }, 'png', 'rs:fill:32:32/enlarge:1')}></link>
                )}

                {data?.meta?.map((attributes) => (
                    <meta key={JSON.stringify(attributes)} {...attributes} />
                ))}

                {preferences?.meta?.map((attributes) => (
                    <meta key={JSON.stringify(attributes)} {...attributes} />
                ))}

            </Head>
            {/* <div style={{ position: 'fixed', bottom: 0, zIndex: 999 }}>
                <textarea style={{ width: '100vw', height: 400 }}>
                    {JSON.stringify(pageProps.meta, null, 2)}
                </textarea>
            </div> */}
            <ReduxProvider store={store}>
                <Component {...pageProps} />
            </ReduxProvider>
        </div>
    )
}

export default ZuprApp
