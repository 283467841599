import jwt_decode from 'jwt-decode'
import { getHost } from './host'

export type TokenSet = {
    access_token: string
    refresh_token: string
}

export interface TokenResponse {
    access: string
    refresh: string
}

interface Credentials {
    email: string
    password: string
}

type Authorize = (credentials: Credentials) => Promise<TokenSet>
type Refresh = (refresh_token: string) => Promise<TokenSet>

type SetTokens = (tokens: TokenSet) => Promise<void>
type DeleteTokens = () => Promise<void>

let access_token: TokenSet['access_token'] | undefined
let refresh_token: TokenSet['refresh_token'] | undefined

// callback function to store/delete tokens in different storages
let setTokens: SetTokens = async () => undefined
let deleteTokens: DeleteTokens = async () => undefined

interface AuthSettings {
    setTokens?: SetTokens
    deleteTokens?: DeleteTokens
}

export const init = (settings: AuthSettings) => {
    if (settings.setTokens) {
        setTokens = settings.setTokens
    }
    if (settings.deleteTokens) {
        deleteTokens = settings.deleteTokens
    }
}

export const tokenRequest = async (endpoint, body) => {
    if (!getHost()) throw Error('No host for @zupr/api/auth. use init()')

    const response = await fetch(`${getHost()}/user/${endpoint}/`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    })

    if (response.status === 200) return response

    throw response
}

export const authorize: Authorize = async (credentials) =>
    tokenRequest('token', {
        ...credentials,
    })
        .then((response) => response.json())
        .then(async (tokens: TokenResponse) => {
            setAccessToken(tokens.access)
            setRefreshToken(tokens.refresh)
            await setTokens({
                access_token: tokens.access,
                refresh_token: tokens.refresh,
            })
            return {
                access_token: tokens.access,
                refresh_token: tokens.refresh,
            }
        })

export const refresh: Refresh = async (refresh_token) => {
    try {
        const tokens: TokenResponse = await tokenRequest('token-refresh', {
            refresh: refresh_token,
        }).then((response) => response.json())

        setAccessToken(tokens.access)
        setRefreshToken(tokens.refresh)

        await setTokens({
            access_token: tokens.access,
            refresh_token: tokens.refresh,
        })

        return {
            access_token: tokens.access,
            refresh_token: tokens.refresh,
        }
    } catch (error) {
        await deleteTokens()
        throw error
    }
}

export const unauthorize = async () => {
    access_token = undefined
    refresh_token = undefined
    await deleteTokens()
}

export const setAccessToken = (token) => (access_token = token)
export const setRefreshToken = (token) => (refresh_token = token)

export const getAccessToken = () => access_token
export const getRefreshToken = () => refresh_token

export interface AccessTokenPayload {
    exp: number
}

// check if token is expired and refresh it
export const getFreshToken = async (): Promise<
    TokenSet['access_token'] | undefined
> => {
    // check if jwt is expired
    if (access_token) {
        try {
            const decoded: AccessTokenPayload = jwt_decode(access_token)
            const expired = decoded.exp < Date.now() / 1000
            if (expired && refresh_token) {
                await refresh(refresh_token)
            }
        } catch {}
        return access_token
    }

    if (refresh_token) {
        const tokens = await refresh(refresh_token)
        return tokens.access_token
    }
}
