const listStore = (state = {}, action) => {
    switch (action.type) {
        case 'LIST_STORE':
            return { ...state, [action.url]: action.list }

        case 'INVALIDATE_ALL':
            return {}

        default:
            return state
    }
}

export default listStore
