import { Location } from '@zupr/types/fo'
import { get as dotGet } from 'lodash'
import qs from 'query-string'
import slugify from 'slug'
import UrlPattern from 'url-pattern'

export const toParams = (pattern, url) => {
    const matcher = new UrlPattern(
        pattern.startsWith('/') ? pattern : `/${pattern}`
    )

    let pathname = url

    if (typeof url !== 'string') {
        console.error('Expect string for toParams', { pattern, url })
    }

    if (url.startsWith('http'))
        pathname = url.replace(new RegExp(/^[^:]+:\/\/[^/?#]+/), '')
    if (!pathname.startsWith('/')) pathname = `/${pathname}`

    return matcher.match(pathname)
}

export const matches = (pattern, url) => {
    return !!toParams(pattern, url)
}

export const toId = (pattern: string, url: string) => {
    if (!pattern || !pattern.includes(':id')) {
        throw new Error('pass pattern with :id to extract id')
    }
    const params = toParams(pattern, url)
    return params.id
}

export const patternToUrl = (pattern: string, params?: Record<string, any>) => {
    const matcher = new UrlPattern(pattern)
    return matcher.stringify(params || {})
}

// extract the ids of linked objects
// returns a unique list
export const extractIds = (
    list: Record<string, any>[],
    key: string,
    pattern: string
) => {
    return Array.from(
        new Set(
            list
                .map((item) => dotGet(item, key))
                .filter((url) => typeof url === 'string') // if url is not available it is discarded
                .map((url) => toId(pattern, url))
        )
    )
}

// returns a unique list
export const extractUniqueIds = (list, key, pattern) => {
    return Array.from(new Set(extractIds(list, key, pattern)))
}

export const addQueryToUrl = (url, params) => {
    if (!params) return url

    if (typeof url !== 'string') {
        console.error('Expect string for addQueryToUrl', { params, url })
    }

    let cleanedParams = Object.entries(params).reduce(
        (obj, [k, v]) => (v == null ? obj : { ...obj, [k]: v }),
        {}
    )
    if (Object.keys(cleanedParams).length) {
        url = `${url}${url && url.includes('?') ? '&' : '?'}${qs.stringify(
            cleanedParams
        )}`
    }
    return url
}

export const parseUrl = (url) => {
    if (!url) return url
    if (typeof url !== 'string') return url
    const parsed = new URL(url)
    return {
        url: url.replace(parsed.search, '').replace(parsed.hash, ''),
        query: (parsed.search && qs.parse(parsed.search)) || {},
    }
}

type LocationPage =
    | 'home'
    | 'information'
    | 'products'
    | 'messages'
    | 'product'
    | 'photos'
    | 'message'

export const locationUrl = (
    location: Location,
    page: LocationPage,
    extra?: string
) => {
    let base: string

    if (typeof location?.baseUrl === 'string') {
        base = location?.baseUrl
    } else {
        if (location?.title) {
            base = `/${patternToUrl('winkel/:id/:slug', {
                id: location.id,
                slug: slugify(location.title),
            })}`
        } else {
            base = `/${patternToUrl('winkel/:id', { id: location.id })}`
        }
    }

    switch (page) {
        case 'home':
            return base || '/'
        case 'information':
            return `${base}/informatie`
        case 'products':
            return `${base}/assortiment`
        case 'messages':
            return `${base}/berichten`
        case 'product':
            return `${base}/product/${extra}`
        case 'photos':
            return `${base}/fotos`
        case 'message':
            return `${base}/bericht/${extra}`
    }
}

export const productUrl = (product) => {
    if (!product || !product.id)
        throw new Error('Need product id for productUrl')
    return `/product/${product.id}`
}

export const productLocationUrl = ({ location, product }) => {
    if (!location?.id)
        throw new Error('Need location id for productLocationUrl')
    if (!product?.id) throw new Error('Need product id for productLocationUrl')

    return locationUrl(location, 'product', product.id)
}

export const storyUrl = (story) => {
    if (!story || !story.locationId)
        throw new Error('Need location id for storyUrl')
    return `/ondernemer/${story.locationId}`
}

export const orderUrl = ({ id }) => {
    return `/bestelling/${id}`
}
