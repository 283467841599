let host =
    (process.env.NEXT_PUBLIC_HOST_API &&
        `https://${process.env.NEXT_PUBLIC_HOST_API}`) ||
    null

let imageProviderHost =
    process.env.NEXT_PUBLIC_IMAGE_PROVIDER_URL || 'https://img.zupr.services'

export const getHost = () => host
export const getImageProviderHost = () => imageProviderHost

export const setApiHost = (h) => (host = h)
export const setImageProviderHost = (h) => (imageProviderHost = h)
