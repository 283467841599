import { combineReducers } from 'redux'

import item from './item'
import list from './list'
import request from './request'

export const reducers = {
    item,
    request,
    list,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
