import { applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'

const logger = createLogger({
    diff: false,
    duration: true,
    collapsed: true,
    logErrors: false,
    timestamp: false,
    predicate: (getState, action) =>
        process.env.NEXT_PUBLIC_DEBUG &&
        !new Set(['LIST_STORE', 'ITEMS_STORE']).has(action.type),
})

export default compose(
    applyMiddleware(logger),
    global.devToolsExtension ? global.devToolsExtension() : (f) => f
)
