export const append = (formData, name, value) => {
    // Api requires a filename for binary uploads
    // Api requires an extension for binary uploads
    // Use filetype to generate filename `images/png` => 'image.png'
    if (value instanceof Blob && !value.filename) {
        return formData.append(name, value, value.type.replace('/', '.'))
    }

    if (value instanceof Blob) {
        return formData.append(name, value)
    }

    if (Array.isArray(value)) {
        return value.forEach((item) => formData.append(name, item))
    }

    // field with object that has a name (binary not blob for expo)
    if (typeof value === 'object' && !!value.uri && !!value.name) {
        formData.append(
            name,
            {
                uri: value.uri,
                name: value.name,
                type: value.type,
            },
            value.name
        )
    }

    if (typeof value === 'object') {
        return formData.append(name, JSON.stringify(value))
    }

    return formData.append(name, value)
}

const createBody = (body) => {
    // then no request body
    if (!body) {
        return null
    }

    // if request body is a string
    if (typeof body === 'string') {
        return body
    }

    // if already formdata
    if (body instanceof FormData) {
        return body
    }

    // detect of some of the values is a binary Blob or react-native style binary object (name, ur)
    // Then we should send the api an multipart/form-data request
    const hasBinary =
        typeof body === 'object' &&
        Object.values(body).some((value) => {
            // ES6 binary blob
            if (value instanceof Blob) {
                return true
            }

            // picked file from react-native
            if (
                !!value &&
                typeof value === 'object' &&
                value.uri &&
                value.name
            ) {
                return true
            }

            return false
        })

    if (hasBinary) {
        const formData = new FormData()
        Object.keys(body).forEach((key) => append(formData, key, body[key]))
        return formData
    }

    return JSON.stringify(body)
}

export default createBody
